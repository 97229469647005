import { Dispatch, SetStateAction, useEffect, useState } from 'react'

const hasLocalStorage = typeof localStorage !== `undefined`

export const useLocalStorage = <T extends unknown>(
  key: string,
  interpret: (input: unknown) => T | undefined = (i) => i as T | undefined
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] => {
  const [state, setState] = useState<T | undefined>(() => {
    try {
      if (!hasLocalStorage) return undefined

      const json = localStorage.getItem(key)
      if (!json) return undefined

      const obj = interpret(JSON.parse(json))
      return obj
    } catch (ex) {
      console.error(`[useLocalStorage]`, ex)
      return undefined
    }
  })

  useEffect(() => {
    if (hasLocalStorage) {
      localStorage.setItem(key, JSON.stringify(state))
    }
  }, [state])

  return [state, setState]
}
