import { AppBar, Box, CssBaseline, Drawer, Fade, Hidden, IconButton, Toolbar } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Menu as MenuIcon } from '@material-ui/icons'
import { mdiArrowLeftBoldCircle, mdiArrowLeftBoldCircleOutline, mdiArrowRightBoldCircle, mdiArrowRightBoldCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { AppDefinition, MAppsButton, MText, MEnvironment } from '@mprise/react-ui'
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useAppsQuery } from '../gateway/react.generated'
import { useCurrentCompanyId } from '../gateway/useCurrentCompany'
import { CompanyValue, SelectCompanyDialog } from '../shared/select-company-dialog'
import { StateSession } from '../state/session'
import { AppDrawer } from './drawer'
import * as serviceWorker from '../shared/serviceWorker'
import { updateApplicationReducer, emptyUpdateApplicationState } from '../shared/update-reducer'
import { UpdateBar } from '../shared/update-bar'

export const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()
  const theme = useTheme()

  const [state, dispatch] = useReducer(updateApplicationReducer, emptyUpdateApplicationState)
  useEffect(() => {
    serviceWorker.register({
      onSuccess: reg => dispatch({ type: 'SERVICEWORKER_INIT', instance: reg }),
      onUpdate: reg => dispatch({ type: 'SERVICEWORKER_UPDATED', instance: reg })
    })
  }, [])

  const { t } = useTranslation()

  const handleToggleDrawer = () => setMobileOpen(x => !x)

  const companyId = useCurrentCompanyId()
  const [, dispatchContext] = useContext(StateSession.Context)
  const [selectCompany, setSelectCompany] = useState(false)
  const selectCompanyAnyway = !companyId
  const handleSelectCompany = () => setSelectCompany(true)

  const [mobileOpen, setMobileOpen] = useState(false)
  const handleCloseDrawer = () => setMobileOpen(false)

  const appsQuery = useAppsQuery({
    fetchPolicy: `cache-first`
  })
  const apps = (appsQuery.data?.my?.tenant?.applications ?? [])
    .map(x => x?.application)
    .filter(x => x?.id && x.name && x.publicUrl)
    .map(
      (x): AppDefinition => ({
        id: x!.id!,
        name: x!.name!,
        url: x!.publicUrl!,
        logoUrl: x!.logoUrl!
      })
    )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <UpdateBar instance={state.instance} updated={state.updated} onUpdate={() => dispatch({ type: 'SERVICEWORKER_RESET' })}>
        <AppBar position='fixed' className={classes.appBar}>
          <Toolbar>
            <Box flexBasis={48 + 16} className={classes.menuButtonContainer}>
              <IconButton color='inherit' edge='start' onClick={handleToggleDrawer} className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
            </Box>
            <MText block textVariant='header'>
              Administration
            </MText>
            <PointerNavigation />
            <span className={classes.environment}>
              <MEnvironment />
            </span>
            <Box flexBasis={0}>{apps.length ? <MAppsButton apps={apps} /> : null}</Box>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden smUp implementation='css'>
            <Drawer
              variant='temporary'
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleToggleDrawer}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true
              }}
            >
              <AppDrawer onClose={handleCloseDrawer} onSelectCompany={handleSelectCompany} />
            </Drawer>
          </Hidden>
          <Hidden mdDown implementation='css'>
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant='permanent'
              open
            >
              <AppDrawer onClose={handleCloseDrawer} onSelectCompany={handleSelectCompany} />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
        <SelectCompanyDialog
          open={selectCompany || selectCompanyAnyway}
          title={t('SelectACompany')}
          onClose={() => setSelectCompany(false)}
          onSave={(company: CompanyValue | null) => {
            if (company) {
              dispatchContext({ setCompanyId: company.id })
            }
            setSelectCompany(false)
          }}
        />
      </UpdateBar>
    </div>
  )
}

const PointerNavigation = () => {
  const history = useHistory()
  const [active, setActive] = useState(false)
  const [start, setStart] = useState<number | null>(null)
  const pending = useRef(false)
  const [current, setCurrent] = useState(0)
  const range = 80
  const state = start === null ? 0 : Math.pow((current - start) / range, 3)
  const handlePointerDown: React.PointerEventHandler<HTMLElement> = e => {
    e.preventDefault()
    e.stopPropagation()

    setStart(e.screenX)
    setCurrent(e.screenX)
    setActive(true)
    pending.current = true
  }
  const handlePointerMove: React.PointerEventHandler<HTMLElement> = e => {
    e.preventDefault()
    e.stopPropagation()

    setCurrent(e.screenX)

    if (Math.abs(state) >= 1 && pending.current) {
      pending.current = false
      setActive(false)
      const forward = state > 0
      history.go(forward ? 1 : -1)
    }
  }
  const handlePointerLeave: React.PointerEventHandler<HTMLElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    pending.current = false
    setActive(false)
  }
  const handlePointerUp: React.PointerEventHandler<HTMLElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    pending.current = false
    setActive(false)
  }
  const leftIcon = state <= -1 ? mdiArrowLeftBoldCircle : mdiArrowLeftBoldCircleOutline
  const rightIcon = state >= 1 ? mdiArrowRightBoldCircle : mdiArrowRightBoldCircleOutline
  return (
    <Box
      display='flex'
      flexGrow={1}
      justifyContent='center'
      alignSelf='stretch'
      alignItems='center'
      style={{ userSelect: `none`, touchAction: `none` }}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerMove={handlePointerMove}
      onPointerLeave={handlePointerLeave}
    >
      <Fade in={active} timeout={{ enter: 150, exit: 600 }}>
        <Box paddingRight={1}>
          <Icon
            path={leftIcon}
            size={2}
            style={{
              opacity: 0.4 + Math.max(-0.4, state * -1) * 0.6,
              transformOrigin: `50% 50%`,
              transform: `translateX(${constrain(state, -1, 0) * 30}px) scale(${1.0 + constrain(state, -1, 1) * -0.3})`
            }}
          />
        </Box>
      </Fade>
      <Fade in={active} timeout={{ enter: 150, exit: 600 }}>
        <Box paddingLeft={1}>
          <Icon
            path={rightIcon}
            size={2}
            style={{
              opacity: 0.4 + Math.max(-0.4, state) * 0.6,
              transformOrigin: `50% 50%`,
              transform: `translateX(${constrain(state, 0, 1) * 30}px) scale(${1.0 + constrain(state, -1, 1) * 0.3})`
            }}
          />
        </Box>
      </Fade>
    </Box>
  )
}

const constrain = (val: number, min: number, max: number) => (val < min ? min : val > max ? max : val)

const drawerWidth = 260

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    userSelect: `none`
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButtonContainer: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    backgroundColor: 'white'
  },
  grow: {
    flexGrow: 1
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    position: 'fixed',
    top: '54px',
    right: '7px',
    padding: '1px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0 2px 8px 2px rgba(0, 0, 0, 0.2)',
    boxSizing: 'border-box'
  },
  cell: {
    display: 'block',
    margin: '5px',
    padding: '5px',
    color: 'rgba(0, 0, 0, 0.94)',
    backgroundColor: '#ebeff2',
    fontSize: '13px',
    lineHeight: '18px',
    textDecoration: 'none',
    border: '1px solid #EDEDEF',
    borderRadius: '4px'
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  arrow: {
    position: 'fixed',
    right: '29px',
    top: '40px',
    border: '7px solid transparent',
    borderBottomColor: '#fff',
    [theme.breakpoints.down(600)]: {
      right: '21px'
    }
  },
  logo: {
    alignSelf: 'center'
  },
  name: {
    margin: 'auto'
  },
  environment: {
    color: 'rgba(0, 0, 0, 0.54)',
    borderRight: '1px solid rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
    display: 'block',
    lineHeight: '1em',
    paddingRight: '.8rem'
  }
}))
