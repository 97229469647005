import { ApplicationInsights, IApplicationInsights, IConfig, IConfiguration } from '@microsoft/applicationinsights-web'

export const createClient = (aiproxyEndpoint: string): IApplicationInsights => {
  const config: IConfiguration & IConfig = {
    autoTrackPageVisitTime: true,
    disableFetchTracking: false,
    enableCorsCorrelation: true,
    enableUnhandledPromiseRejectionTracking: true,
    endpointUrl: aiproxyEndpoint,
    instrumentationKey: aiproxyEndpoint,
  }

  const appInsights = new ApplicationInsights({ config })
  appInsights.loadAppInsights()

  return appInsights
}
