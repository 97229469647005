import styled from '@emotion/styled'
import { Box, Collapse, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TuneIcon from '@material-ui/icons/Tune'
import { MAvatar, spacing } from '@mprise/react-ui'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { RoleVisible } from '../auth'
import { useAppsQuery, useMeQuery } from '../gateway/react.generated'
import { useCurrentCompany } from '../gateway/useCurrentCompany'
import {
  IconActivity,
  IconAgriware,
  IconDataSecurity,
  IconItem,
  IconJob,
  IconLocation,
  IconPosition,
  IconResource,
  IconResources,
  IconSkill,
  IconTeam,
  IconTrackingId,
  IconTrackingIdStatus,
  IconWork,
  IconWorkItemTemplate,
  IconInspect,
  IconCompression,
  IconContainers,
  HelpOutlineIcon,
  IconSync
} from '../icons'
import logo from '../shared/logo-normal.svg'
import { StateSettings } from '../state/settings'

export const AppDrawer = ({ onClose, onSelectCompany }: { onClose: () => void; onSelectCompany: () => void }) => {
  const { t } = useTranslation()
  const meQuery = useMeQuery()
  const appsQuery = useAppsQuery()
  const companyQuery = useCurrentCompany()
  const settings = useContext(StateSettings.Context)

  return (
    <Box height='100%' flexDirection='column' display='flex'>
      <Box flex={1}>
        <List disablePadding>
          <ListItem button component={RouterLink} to='/' disableRipple onClick={onClose}>
            <img alt={t(`Mprise Agriware Logo`)} src={logo} style={{ width: `100%`, maxHeight: 48, padding: 12, paddingTop: 4, paddingBottom: 4 }} />
          </ListItem>
          <Divider />
          <ListItem button onClick={onSelectCompany}>
            <ListItemIcon>
              <MAvatar.Badge seed={meQuery.data?.my?.user?.id ?? ``}>
                <MAvatar.Icon.Resource />
              </MAvatar.Badge>
            </ListItemIcon>
            <ListItemText primary={meQuery.data?.my?.user?.account?.email ?? `(Name missing)`} secondary={companyQuery.data?.name ?? t('NoCompanySelected')} />
          </ListItem>
          <Divider />
          <RoleVisible role='ADMIN_AGRIWARE'>
            <AppDrawer.MenuItemGroup icon={IconAgriware} name={t('Agriware')}>
              {/* <ListItemPage to="/import" icon={IconAgriware} children={t('Import')} onClick={onClose} /> */}
              <ListItemPage to='/activities' icon={IconActivity} children={t('Activities')} onClick={onClose} />
              <ListItemPage to='/items' icon={IconItem} children={t('Items')} onClick={onClose} />
              <ListItemPage to='/reasons' icon={HelpOutlineIcon} children={t('Reasons')} onClick={onClose} />
              <ListItemPage to='/jobs' icon={IconJob} children={t('Jobs')} onClick={onClose} />
              <ListItemPage to='/locations' icon={IconLocation} children={t('Locations')} onClick={onClose} />
              <ListItemPage to='/positions' icon={IconPosition} children={t('Positions')} onClick={onClose} />
              <ListItemPage to='/resources' icon={IconResource} children={t('Resources')} onClick={onClose} />
              <ListItemPage to='/skills' icon={IconSkill} children={t('Skills')} onClick={onClose} />
              <ListItemPage to='/carrierids' icon={IconTrackingId} children={t('Carrier Ids')} onClick={onClose} />
              <ListItemPage to='/trackingIds' icon={IconTrackingId} children={t('Tracking Ids')} onClick={onClose} />
              <ListItemPage to='/lots' icon={IconTrackingId} children={t('Lots')} onClick={onClose} />
              <ListItemPage to='/containers' icon={IconContainers} children={t('Containers')} onClick={onClose} />
            </AppDrawer.MenuItemGroup>
          </RoleVisible>
          
          <Divider />
          
          <RoleVisible role='ADMIN_DATA_SECURITY'>
            <AppDrawer.MenuItem to='/data-security' name={t('Data Security')} icon={IconDataSecurity} onClick={onClose} />
          </RoleVisible>
          
          <Divider />
          
          {appsQuery.data?.my?.tenant?.applications?.some(a => a?.application?.name.includes('Greenhouse')) ?
            <>
              <AppDrawer.MenuItemGroup icon={IconItem} name={t('Greenhouse')}>
                <RoleVisible role='ADMIN_TEMPLATE'>
                  <ListItemPage to='/greenhouse-templates' icon={IconWorkItemTemplate} children={t('Templates')} onClick={onClose} />
                </RoleVisible>
              </AppDrawer.MenuItemGroup>

              <Divider />
            </>
          : null}

          <AppDrawer.MenuItemGroup icon={IconWork} name={t('Work')}>
            <RoleVisible role='ADMIN_STATUS'>
              <ListItemPage to='/tracking-id-status' icon={IconTrackingIdStatus} children={t('Statuses')} onClick={onClose} />
            </RoleVisible>
            <RoleVisible role='ADMIN_TEAM'>
              <ListItemPage to='/teams' icon={IconResources} children={t('Teams')} onClick={onClose} />
            </RoleVisible>
            <RoleVisible role='ADMIN_PLANNINGROUP'>
              <ListItemPage to='/resource-planning-groups' icon={IconTeam} children={t('Planning Group')} onClick={onClose} />
            </RoleVisible>
            <RoleVisible role='ADMIN_TEMPLATE'>
              <ListItemPage to='/work-item-templates' icon={IconWorkItemTemplate} children={t('Templates')} onClick={onClose} />
            </RoleVisible>
          </AppDrawer.MenuItemGroup>

          <Divider />

          {meQuery.data?.my?.user?.roles?.flatMap(r => r?.permissions).map(p => p?.id).some(r => r === 'INSPECT_COMPRESSOR' || r === 'INSPECT_ADMIN') &&
            <>
              <AppDrawer.MenuItemGroup icon={IconInspect} name={t('Inspect')}>
                <RoleVisible role='INSPECT_COMPRESSOR'>
                  <ListItemPage to='/compression' icon={IconCompression} children={t('Compression')} onClick={onClose} />
                </RoleVisible>
                <RoleVisible role='INSPECT_ADMIN'>
                  <ListItemPage to='/inspect/synchronization' icon={IconSync} children={t('Synchronization')} onClick={onClose} />
                </RoleVisible>
              </AppDrawer.MenuItemGroup>

              <Divider />
            </>
          }
        </List>
      </Box>
      <Box>
        <List disablePadding>
          <ListItemPage nested={false} to='/settings' icon={TuneIcon} children={t('Settings')} onClick={onClose} />

          <ListItem dense>
            <ListItemText>
              <Typography variant='caption' color='textSecondary'>
                {settings.version}
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </Box>
  )
}

const StyledShadedIcon = styled.div`
  padding: ${spacing(1)};
  width: ${spacing(8)};
  height: ${spacing(8)};
  background-color: #00000010;
  border-radius: ${spacing(3)};
`
function ListItemPage ({
  children,
  icon: Icon,
  to,
  onClick,
  nested = true
}: {
  nested?: boolean
  children: React.ReactNode
  icon: React.ComponentType
  to: string
  onClick: () => void
}) {
  const classes = useStyles()

  return (
    <ListItem className={nested ? classes.nested : classes.normal} button component={RouterLink} onClick={onClick} to={to}>
      <ListItemIcon>
        <StyledShadedIcon>
          <Icon />
        </StyledShadedIcon>
      </ListItemIcon>
      <ListItemText>{children}</ListItemText>
    </ListItem>
  )
}

AppDrawer.MenuItemGroup = ({ children, icon: Icon, name }: { children: React.ReactNode; icon: React.ComponentType; name: any }) => {
  const [open, setOpen] = useState(false)
  const handleToggle = () => setOpen(x => !x)

  return (
    <>
      <ListItem button onClick={handleToggle}>
        <ListItemIcon>
          <StyledShadedIcon>
            <Icon />
          </StyledShadedIcon>
        </ListItemIcon>
        <ListItemText>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box flex={1}>{name}</Box>
            {/* <ListSubheader disableGutters>{name}</ListSubheader> */}
            <Box display='flex'>{open ? <ExpandLessIcon color='action' /> : <ExpandMoreIcon color='action' />}</Box>
          </Box>
        </ListItemText>
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List dense disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  )
}
AppDrawer.MenuItem = ({ icon: Icon, name, to, onClick }: { icon: React.ComponentType; name: any; to: string,onClick: () => void }) => {
  return (
    <>
      <ListItem button component={RouterLink} to={to} onClick={onClick}>
        <ListItemIcon>
          <StyledShadedIcon>
            <Icon />
          </StyledShadedIcon>
        </ListItemIcon>
        <ListItemText>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box flex={1}>{name}</Box>
            {/* <ListSubheader disableGutters>{name}</ListSubheader> */}
            {/* <Box display="flex">{open ? <ExpandLessIcon color="action" /> : <ExpandMoreIcon color="action" />}</Box> */}
          </Box>
        </ListItemText>
      </ListItem>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(5)
  },
  normal: {
    paddingLeft: theme.spacing(2)
  }
}))
