import { IApplicationInsights } from '@microsoft/applicationinsights-web'
import { Location } from 'history'
import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'
import { AppTelemetryInitializer } from './initializers'

const AppInsightsContext = React.createContext<null | IApplicationInsights>(null)

export const useAppInsights = () => React.useContext(AppInsightsContext)

export const AppInsightsProvider = ({ appInsights, children }: { appInsights: IApplicationInsights; children: React.ReactNode }) => {
  const history = useHistory()
  const app = useMemo(() => new AppTelemetryInitializer(), [])

  // Telemetry initializers
  useEffect(() => {
    appInsights.addTelemetryInitializer(app.process.bind(app))
  }, [appInsights])

  // Router page view tracking
  useEffect(() => {
    const trackPageView = (location: Location) => {
      appInsights.appInsights.trackPageView({ uri: location.pathname })
    }

    trackPageView(history.location)
    const stopListen = history.listen(trackPageView)

    return () => stopListen()
  }, [history, appInsights])

  return <AppInsightsContext.Provider value={appInsights}>{children}</AppInsightsContext.Provider>
}
