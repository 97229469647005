import Button from '@material-ui/core/Button'
import { MpriseAuthProvider } from '@mprise/react-auth'
import {
  MAvatar,
  MDialog,
  MSelectItem,
  PaginatedSearchAndSelect,
  ScanningSetting,
  ToMSelectItem,
} from '@mprise/react-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  CompaniesByFilterQuery,
  useCompaniesByFilterQuery,
} from '../gateway/react.generated'
import { useDebounceValue } from '../shared/debounce-value'


import { defined, NotOptional } from '../shared/typescript';
import { QueryErrorMessage } from './react-apollo'
import { useLocalState } from './react-local-state'

export type CompanyValue = NotOptional<
  NotOptional<CompaniesByFilterQuery['masterdata']['companies']['page']>[0]
>

const toSelectItem = ToMSelectItem<CompanyValue>({
  id: (x) => x.id,
  icon: () => <MAvatar.Icon.Company />,
  primary: (x) => x.name ?? ``,
})

export const SelectCompanyDialog = ({
  title,
  open,
  onClose,
  onSave,
}: {
  title: React.ReactNode
  open: boolean
  onClose: () => void
  onSave: (values: CompanyValue | null) => void
}) => {
  const { t } = useTranslation()

  const { mpriseId } = MpriseAuthProvider.useContext()
  const handleLogout = () => {
    mpriseId?.logout()
  }

  const [search, setSearch] = useLocalState(``, [open])
  // To improve perfomance, delay search to prevent sending new queries for every
  // keystroke
  const debouncedSearch = useDebounceValue(search, 500)
  const companyQuery = useCompaniesByFilterQuery({
    variables: {      
      companiesCursor: null,
      where: {
        or: [{ name_containslower: debouncedSearch }],
      }
    },
  })

  const handleSelect = async (item: MSelectItem<CompanyValue> | null) => {
    onSave(item?.data ?? null)
  }

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  const handleNextPage = (cursor: string) => {
    companyQuery.fetchMore({ variables: { companieCursor: cursor } })
  }

  const companies = companyQuery.data?.masterdata.companies
  const nextCursor = companies?.nextCursor ?? null
  const items = companies?.page?.filter(defined).map(toSelectItem) ?? null

  return (
    <MDialog
      minWidth="sm"
      open={open}
      title={title}
      onClose={onClose}
      onSubmit={handleSubmit}
      footer={
        (companyQuery.error || companyQuery.data?.masterdata.companies.total === 0) ? (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogout}
          >
            {t(`Logout`)}
          </Button>
        ) : null
      }
    >
      <QueryErrorMessage query={companyQuery} />
      <PaginatedSearchAndSelect
        scanningSetting={ScanningSetting.KEYBOARD}
        items={items}
        nextCursor={nextCursor}
        search={search}
        value={null}
        onChange={handleSelect}
        onNextPage={handleNextPage}
        onSearch={setSearch}
        showSwitchKeyboardOption={true}
      />
    </MDialog>
  )
}
