import { useContext } from 'react'
import { StateSession } from '../state/session'
import { useCompaniesByFilterQuery } from '../gateway/react.generated'

export const useCurrentCompanyId = () => useContext(StateSession.Context)[0].companyId

export const useCurrentCompany = () => {
  const companyId = useCurrentCompanyId()
  const query = useCompaniesByFilterQuery({
    fetchPolicy: `cache-first`,
    variables: {
      where: {
        id_eq: companyId,
      },
    },
  })
  const company = query.data?.masterdata.companies?.page[0]
  return { ...query, data: company }
}
