import React, { Dispatch, useEffect } from 'react'
import { useImmerReducer } from '../shared/immer-reducer'
import { useLocalStorage } from '../shared/local-storage'

export interface Session {
  companyId: string
}

export interface Action {
  setCompanyId?: string
}

export const StateSession = ({ children }: { children: React.ReactNode }) => {
  const [settings, setSettings] = useLocalStorage<Session>(`stateSession`)

  const initialState: Session = settings ?? {
    companyId: ``,
  }

  const [state, dispatch] = useImmerReducer<Session, Action>((state, action) => {
    if (action.setCompanyId) {
      state.companyId = action.setCompanyId
    }
  }, initialState)

  useEffect(() => {
    setSettings(state)
  }, [state])

  return <StateSession.Context.Provider value={[state, dispatch]}>{children}</StateSession.Context.Provider>
}
StateSession.Context = React.createContext<[Session, Dispatch<Action>]>([
  { companyId: `` },
  () => {
    throw Error(`requires <AppSession>...</AppSession>`)
  },
])
