import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'


// Code splitting for routes
const lazyActivity = React.lazy(() => import(`./activity/page`).then((x) => ({ default: x.ActivityList })))
const lazyDashboard = React.lazy(() => import(`./dashboard`).then((x) => ({ default: x.DashboardRoute })))
const lazySettings = React.lazy(() => import(`./settings/page`).then((x) => ({ default: x.default })))

// const lazyImport = React.lazy(() => import(`./import/page`).then((x) => ({ default: x.ImportPage })))
const lazyItem = React.lazy(() => import(`./item/page`).then((x) => ({ default: x.ItemList })))
const lazyJob = React.lazy(() => import(`./job/page`).then((x) => ({ default: x.JobList })))
const lazyLocation = React.lazy(() => import(`./location/page`).then((x) => ({ default: x.LocationList })))
const lazyPosition = React.lazy(() => import(`./position/page`).then((x) => ({ default: x.PositionList })))
const lazyResourceList = React.lazy(() => import(`./resource/page`).then((x) => ({ default: x.ResourceList })))
const lazySkillDialog = React.lazy(() => import(`./skill/dialog`).then((x) => ({ default: x.SkillDialog })))
const lazySkillPage = React.lazy(() => import(`./skill/page`).then((x) => ({ default: x.SkillPage })))
const lazyTeamRoute = React.lazy(() => import(`./team/page`).then((x) => ({ default: x.TeamRoute })))
const lazyGHTemplateDialog = React.lazy(() => import(`./greenhouse-template/dialog`).then((x) => ({ default: x.GHTemplateDialog })))
const lazyTemplateDialog = React.lazy(() => import(`./work-item-template/dialog`).then((x) => ({ default: x.TemplateDialog })))
const lazyTrackingId = React.lazy(() => import(`./trackingid/page`).then((x) => ({ default: x.TrackingIdList })))
const lazyCarrierId = React.lazy(() => import(`./carrierid/page`).then((x) => ({ default: x.CarrierIdPage })))

const lazyCompressionPage = React.lazy(() => import(`./compression/page`).then((x) => ({ default: x.TenantSettingsPage })))
const lazyCompressionDialog = React.lazy(() => import(`./compression/dialog`).then((x) => ({ default: x.TenantSettingsDialog })))
const lazySynchronizationSettingsPage = React.lazy(() => import(`./inspect-synchronization/page`).then((x) => ({ default: x.SynchronizationSettingsPage })))
const lazyLots = React.lazy(() => import(`./lots/page`).then((x) => ({ default: x.LotPage })))
const lazyContainers = React.lazy(() => import(`./containers/page`).then((x) => ({ default: x.ContainersPage })))
const lazyReasons=React.lazy(() => import(`./reasons/page`).then((x) => ({ default: x.reasonsPage })))
const lazyTrackingIdDialog = React.lazy(() => import(`./tracking-id-status/dialog`).then((x) => ({ default: x.WarehouseStatusDialogRoute })))
const lazyTrackingIdStatusList = React.lazy(() => import(`./tracking-id-status/page`).then((x) => ({ default: x.WarehouseStatusList })))
const lazyGHWorkItemTemplateList = React.lazy(() => import(`./greenhouse-template/page`).then((x) => ({ default: x.GHWorkItemTemplateList })))
const lazyWorkItemTemplateList = React.lazy(() => import(`./work-item-template/page`).then((x) => ({ default: x.WorkItemTemplateList })))
const lazyResourcePlanningGroup = React.lazy(() => import('./resource-planning-group/page').then((x) => ({ default: x.ResourcePlanningGroupRoute })))
const lazyDataSecurity = React.lazy(() => import('./data-security/index').then((x) =>  ({ default: x.DataSecurity })))

export const AppRoutes = () => {


  return (
    <>
      <Switch>
        <Route path="/dashboard/:tab?" exact component={lazyDashboard} />
        <Route path="/settings" exact component={lazySettings} />
        <Route path="/teams" exact component={lazyTeamRoute} />
        <Route path={[`/greenhouse-templates`, `/greenhouse-templates/:action`, `/greenhouse-templates-:templateId`]} exact component={lazyGHWorkItemTemplateList} />
        <Route path={[`/work-item-templates`, `/work-item-templates/:action`, `/work-item-templates-:templateId`]} exact component={lazyWorkItemTemplateList} />
        <Route
          path={['/tracking-id-status', '/tracking-id-status/:action', '/tracking-id-status-:trackingIdStatusId']}
          exact
          component={lazyTrackingIdStatusList}
        />
        {/* <Route path="/import" exact component={lazyImport} /> */}
        <Route path="/resources" exact component={lazyResourceList} />
        <Route path="/activities" exact component={lazyActivity} />
        <Route path="/items" exact component={lazyItem} />
        <Route path="/jobs" exact component={lazyJob} />
        <Route path="/locations" exact component={lazyLocation} />
        <Route path="/positions" exact component={lazyPosition} />
        <Route path={['/teams']} exact component={lazyTeamRoute} />
        <Route path="/resource-planning-groups" exact component={lazyResourcePlanningGroup} />
        <Route path="/trackingids" exact component={lazyTrackingId} />
        <Route path="/carrierids" exact component={lazyCarrierId} />
        <Route path={[`/skills`, `/skills/new`, `/skills-:skillId`]} exact component={lazySkillPage} />
        <Route path={[`/greenhouse-templates`, `/greenhouse-templates/:action`, `/greenhouse-templates-:templateId`]} exact component={lazyGHWorkItemTemplateList} />
        <Route path={[`/work-item-templates`, `/work-item-templates/:action`, `/work-item-templates-:templateId`]} exact component={lazyWorkItemTemplateList} />
        <Route path={"/data-security"} exact component={lazyDataSecurity} />
        <Route path={["/compression", '/compression/edit-:setting']} exact component={lazyCompressionPage} />
        <Route path={"/inspect/synchronization"} exact component={lazySynchronizationSettingsPage} />
        <Route path="/lots" exact component={lazyLots} />
        <Route path="/containers" exact component={lazyContainers} />
        <Route path={"/reasons"} exact component={lazyReasons}/>

        <Redirect path="/" to="/dashboard" exact />
      </Switch>
      <Switch>
        <Route path={['/skills/new', '/skills-:skillId']} exact component={lazySkillDialog} />
        <Route path={['/compression/edit-:setting']} exact component={lazyCompressionDialog} />

        <Route path={['/tracking-id-status/new', '/tracking-id-status-:trackingIdStatusId']} exact component={lazyTrackingIdDialog} />
        <Route path={[`/greenhouse-templates/:action`, `/greenhouse-templates-:templateId`]} exact component={lazyGHTemplateDialog} />
        <Route path={[`/work-item-templates/:action`, `/work-item-templates-:templateId`]} exact component={lazyTemplateDialog} />
      </Switch>
    </>
  )
}
