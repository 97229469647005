import { createMpriseAuthRoleBasedComponents } from '@mprise/react-auth'

// https://dev.azure.com/mprise-indigo/AgriwareDevelopment/_wiki/wikis/AgriwareDevelopment.wiki/121/Authorization

export const { RoleVisible, RoleMissing, useRoleAvailable } = createMpriseAuthRoleBasedComponents([
  `ADMIN_STATUS`,
  `ADMIN_STATUS_ADMIN`,
  `ADMIN_TEAM`,
  `ADMIN_TEAM_ADMIN`,
  `ADMIN_PLANNINGROUP`,
  `ADMIN_PLANNINGROUP_ADMIN`,
  `ADMIN_TEMPLATE`,
  `ADMIN_TEMPLATE_ADMIN`,
  `ADMIN_AGRIWARE`,
  `ADMIN_DATA_SECURITY`,
  `ADMIN_DATA_SECURITY_ADMIN`,
  `INSPECT_COMPRESSOR`,
  `INSPECT_ADMIN`,
])
