import { AppBar, Toolbar, Typography, Box, Button, NoSsr } from '@material-ui/core'
import { MpriseGatewayApolloClient } from '@mprise/gateway-client'
import { MpriseAuthProvider, MpriseIdentityServer } from '@mprise/react-auth'
import { MpriseMuiThemeProvider, MpriseTheme } from '@mprise/react-ui'
import { buildClientSchema } from 'graphql'
import React, { ErrorInfo, Suspense, useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { createClient } from './appInsights/client'
import { AppInsightsProvider } from './appInsights/context'
import GatewayFragment from './gateway/fragment.generated'
import { GatewayGeneratedTypePolicies } from './gateway/react-type-policies.generated'
import { TenantUser, TypedTypePolicies } from './gateway/react.generated'
import { IconError } from './icons'
import './lib/translations/i18n'
import { AppLayout } from './route/layout'
import { AppRoutes } from './route/routes'
import { Splash } from './splash'
import { StateLocale } from './state/locale'
import { StateSession } from './state/session'
import { StateSettings } from './state/settings'
import * as OfflinePluginRuntime from 'offline-plugin/runtime'
OfflinePluginRuntime.install()

const startup = async () => {
  const appInsights = createClient(`https://api-dev.agriware.cloud/appinsights/v2/track`)

  ReactDOM.render(
    <React.StrictMode>
      <MpriseTheme />
      <MpriseMuiThemeProvider>
        <ErrorBoundary>
          <NoSsr>
            <BrowserRouter>
              <AppInsightsProvider appInsights={appInsights}>
                <StateSettings>
                  <StateSession>
                    <StateLocale>
                      <Root />
                    </StateLocale>
                  </StateSession>
                </StateSettings>
              </AppInsightsProvider>
            </BrowserRouter>
          </NoSsr>
        </ErrorBoundary>
      </MpriseMuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

const typePolicies: TypedTypePolicies = {
  ...(GatewayGeneratedTypePolicies as any)
}

const schema = buildClientSchema(require(`./gateway/gateway.schema.json`))

const Root = () => {
  const settings = useContext(StateSettings.Context)
  const [mpriseId] = useState(() => new MpriseIdentityServer(settings.login, settings.clientId))
  return (
    <MpriseAuthProvider mpriseId={mpriseId}>
      <MpriseGatewayApolloClient batch schema={schema} gatewayUrl={settings.gateway} typePolicies={typePolicies} possibleTypes={GatewayFragment.possibleTypes}>
        <Suspense fallback={<Splash />}>
          <Splash>
            <AppLayout>
              <AppRoutes />
            </AppLayout>
          </Splash>
        </Suspense>
      </MpriseGatewayApolloClient>
    </MpriseAuthProvider>
  )
}

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { error?: Error; errorInfo?: ErrorInfo }> {
  static clearState() {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = `/`
  }

  static refresh() {
    window.location.reload()
  }

  constructor(props: { children: React.ReactNode }) {
    super(props)

    this.state = {}
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(`[error-boundary]`, { error, errorInfo })
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.error) {
      return (
        <>
          <AppBar>
            <Toolbar>
              <Typography variant="subtitle1">Oops!</Typography>
            </Toolbar>
          </AppBar>
          <Box display="flex" flexDirection="column" alignItems="center" margin="8rem 2rem 2rem 2rem">
            <IconError color="disabled" style={{ fontSize: `5rem` }} />
            <Typography variant="subtitle2">An error occurred</Typography>
          </Box>
          <Box display="flex" justifyContent="center" margin="4rem" style={{ gap: `2rem` }}>
            <Button color="secondary" variant="contained" onClick={ErrorBoundary.clearState}>
              Clear state
            </Button>
            <Button color="primary" variant="contained" onClick={ErrorBoundary.refresh}>
              Reload
            </Button>
          </Box>
          <Box display="flex" flexDirection="column" margin="4rem">
            <Typography variant="subtitle2">Error details:</Typography>
            <pre>{this.state.error.message}</pre>
          </Box>
        </>
      )
    } else {
      return this.props.children
    }
  }
}

const startTime = Date.now()
startup()
  .then(() => {
    const duration = Date.now() - startTime
    console.log(`Initialized in ${(duration / 1000).toFixed(1)}s`)
  })
  .catch((error) => {
    console.error(`Startup error:`, error)
    const root = document.getElementById('root')
    if (root) {
      root.innerText = `Oops! ${String(error.message ?? error)}`
    }
  })
