
        import { GraphQLCosmosPageTypePolicy, EntityTypePolicy, MutationTypePolicy, StaticNodeTypePolicy } from '@mprise/gateway-client';

        export const GatewayGeneratedTypePolicies = {
            // Paginated
            ActivityPage: GraphQLCosmosPageTypePolicy,
            AttributePage: GraphQLCosmosPageTypePolicy,
            AttributesTemplatePage: GraphQLCosmosPageTypePolicy,
            BinPage: GraphQLCosmosPageTypePolicy,
            CarrierIdPage: GraphQLCosmosPageTypePolicy,
            CommentPage: GraphQLCosmosPageTypePolicy,
            CompanyPage: GraphQLCosmosPageTypePolicy,
            ContainerPage: GraphQLCosmosPageTypePolicy,
            ContainerProductPage: GraphQLCosmosPageTypePolicy,
            CropPage: GraphQLCosmosPageTypePolicy,
            CropTypePage: GraphQLCosmosPageTypePolicy,
            FarmPage: GraphQLCosmosPageTypePolicy,
            FieldPage: GraphQLCosmosPageTypePolicy,
            HybridPage: GraphQLCosmosPageTypePolicy,
            InspectionPage: GraphQLCosmosPageTypePolicy,
            ItemPage: GraphQLCosmosPageTypePolicy,
            JobInspectionPage: GraphQLCosmosPageTypePolicy,
            JobPage: GraphQLCosmosPageTypePolicy,
            JobTypePage: GraphQLCosmosPageTypePolicy,
            LocationPage: GraphQLCosmosPageTypePolicy,
            LotPage: GraphQLCosmosPageTypePolicy,
            MPContainerProductPage: GraphQLCosmosPageTypePolicy,
            MediaFilePage: GraphQLCosmosPageTypePolicy,
            PlanningTeamCapacityPage: GraphQLCosmosPageTypePolicy,
            PositionPage: GraphQLCosmosPageTypePolicy,
            ProductCategoryPage: GraphQLCosmosPageTypePolicy,
            ProductPage: GraphQLCosmosPageTypePolicy,
            ReasonPage: GraphQLCosmosPageTypePolicy,
            ResourcePage: GraphQLCosmosPageTypePolicy,
            ResourcePlanningGroupPage: GraphQLCosmosPageTypePolicy,
            SkillPage: GraphQLCosmosPageTypePolicy,
            TeamPage: GraphQLCosmosPageTypePolicy,
            TrackingIdPage: GraphQLCosmosPageTypePolicy,
            TypePage: GraphQLCosmosPageTypePolicy,
            UserPage: GraphQLCosmosPageTypePolicy,
            WarehouseTrackingIdPage: GraphQLCosmosPageTypePolicy,
            WarehouseTrackingStatusPage: GraphQLCosmosPageTypePolicy,
            WorkCommentPage: GraphQLCosmosPageTypePolicy,
            WorkItemPage: GraphQLCosmosPageTypePolicy,
            WorkItemTemplatePage: GraphQLCosmosPageTypePolicy,
            WorkTaskPage: GraphQLCosmosPageTypePolicy,

            // Entities
            Account: EntityTypePolicy,
            Activity: EntityTypePolicy,
            Address: EntityTypePolicy,
            AppLogs: EntityTypePolicy,
            Application: EntityTypePolicy,
            Attribute: EntityTypePolicy,
            AttributeValue: EntityTypePolicy,
            AttributesTemplate: EntityTypePolicy,
            Bin: EntityTypePolicy,
            CarrierId: EntityTypePolicy,
            Comment: EntityTypePolicy,
            Company: EntityTypePolicy,
            Container: EntityTypePolicy,
            ContainerProduct: EntityTypePolicy,
            CosmosResource: EntityTypePolicy,
            Crop: EntityTypePolicy,
            CropType: EntityTypePolicy,
            ExternalResource: EntityTypePolicy,
            Farm: EntityTypePolicy,
            Field: EntityTypePolicy,
            Hybrid: EntityTypePolicy,
            IdentitySubject: EntityTypePolicy,
            IdentityTenant: EntityTypePolicy,
            IdentityTenantLoginMethod: EntityTypePolicy,
            InspectTenant: EntityTypePolicy,
            Inspection: EntityTypePolicy,
            Item: EntityTypePolicy,
            Job: EntityTypePolicy,
            JobInspection: EntityTypePolicy,
            JobPosition: EntityTypePolicy,
            JobProductionPhase: EntityTypePolicy,
            JobType: EntityTypePolicy,
            License: EntityTypePolicy,
            LicensePermissionDto: EntityTypePolicy,
            LicensePermissionRequirement: EntityTypePolicy,
            LicensePermit: EntityTypePolicy,
            LicenseSetDto: EntityTypePolicy,
            LicenseTemplate: EntityTypePolicy,
            Location: EntityTypePolicy,
            Lot: EntityTypePolicy,
            MPContainerProduct: EntityTypePolicy,
            MSItem: EntityTypePolicy,
            MSJob: EntityTypePolicy,
            MSJobInventoryDetail: EntityTypePolicy,
            MSPosition: EntityTypePolicy,
            MediaFile: EntityTypePolicy,
            Note: EntityTypePolicy,
            Organization: EntityTypePolicy,
            OutboundJobInventory: EntityTypePolicy,
            PlanningTeamCapacity: EntityTypePolicy,
            PlanningTeamRule: EntityTypePolicy,
            Position: EntityTypePolicy,
            PostgresResource: EntityTypePolicy,
            Product: EntityTypePolicy,
            ProductCategory: EntityTypePolicy,
            ReadOnlyFarm: EntityTypePolicy,
            Reason: EntityTypePolicy,
            Resource: EntityTypePolicy,
            ResourcePlanningGroup: EntityTypePolicy,
            RoleDto: EntityTypePolicy,
            RuLogs: EntityTypePolicy,
            ShortcutInspection: EntityTypePolicy,
            Skill: EntityTypePolicy,
            Task: EntityTypePolicy,
            Team: EntityTypePolicy,
            Tenant: EntityTypePolicy,
            TenantApplication: EntityTypePolicy,
            TenantRole: EntityTypePolicy,
            TenantRolePermission: EntityTypePolicy,
            TenantService: EntityTypePolicy,
            TenantServiceToken: EntityTypePolicy,
            TenantUser: EntityTypePolicy,
            TimeReg: EntityTypePolicy,
            TrackingId: EntityTypePolicy,
            Type: EntityTypePolicy,
            User: EntityTypePolicy,
            WarehouseTrackingId: EntityTypePolicy,
            WarehouseTrackingStatus: EntityTypePolicy,
            WorkCarrierIdAssembly: EntityTypePolicy,
            WorkCarrierIdAssemblyEntry: EntityTypePolicy,
            WorkComment: EntityTypePolicy,
            WorkCommentThread: EntityTypePolicy,
            WorkItem: EntityTypePolicy,
            WorkItemConsumption: EntityTypePolicy,
            WorkItemConsumptionPlanned: EntityTypePolicy,
            WorkItemConsumptionReported: EntityTypePolicy,
            WorkItemInventoryPicking: EntityTypePolicy,
            WorkItemInventoryPickingEntry: EntityTypePolicy,
            WorkItemOutput: EntityTypePolicy,
            WorkItemOutputEntry: EntityTypePolicy,
            WorkItemTemplate: EntityTypePolicy,
            WorkJobInventoryConsumption: EntityTypePolicy,
            WorkJobInventoryConsumptionEntry: EntityTypePolicy,
            WorkJobInventoryOutput: EntityTypePolicy,
            WorkJobInventoryOutputEntry: EntityTypePolicy,
            WorkJobInventoryPicking: EntityTypePolicy,
            WorkJobInventoryPickingEntry: EntityTypePolicy,
            WorkJobInventoryPutAway: EntityTypePolicy,
            WorkJobInventoryPutAwayEntry: EntityTypePolicy,
            WorkTask: EntityTypePolicy,
            WorkTrackingIdAssembly: EntityTypePolicy,
            WorkTrackingIdAssemblyEntry: EntityTypePolicy,
            WorkTrackingIdStatusChange: EntityTypePolicy,
            WorkTrackingIdStatusChangeEntry: EntityTypePolicy,

            // Mutations
            LicenseMutation: MutationTypePolicy,
            Mutation: MutationTypePolicy,
            OrganizationMutation: MutationTypePolicy,
            TeamMutation: MutationTypePolicy,
            TenantMutation: MutationTypePolicy,
            WarehouseTrackingStatusMutation: MutationTypePolicy,
            WorkItemMutation: MutationTypePolicy,
            WorkItemTemplateMutation: MutationTypePolicy,
            WorkTaskMutation: MutationTypePolicy,

            // Static Node Types
            AccountAudit: StaticNodeTypePolicy,
            AccountExternal: StaticNodeTypePolicy,
            AccountExternalClaim: StaticNodeTypePolicy,
            ApplicationPermission: StaticNodeTypePolicy,
            AttributeOption: StaticNodeTypePolicy,
            AttributeThreshold: StaticNodeTypePolicy,
            CollectionSegmentInfo: StaticNodeTypePolicy,
            CompressionSettings: StaticNodeTypePolicy,
            Contact: StaticNodeTypePolicy,
            Dashboard: StaticNodeTypePolicy,
            DashboardHours: StaticNodeTypePolicy,
            DataPermissions: StaticNodeTypePolicy,
            GerminationCountResults: StaticNodeTypePolicy,
            IdentityServiceTokenCreated: StaticNodeTypePolicy,
            Inheritance: StaticNodeTypePolicy,
            InspectTenantSettings: StaticNodeTypePolicy,
            JobInventoryDetailResult: StaticNodeTypePolicy,
            LicenseUsage: StaticNodeTypePolicy,
            LoadAccepted: StaticNodeTypePolicy,
            LoadInputScan: StaticNodeTypePolicy,
            LoadOutputScan: StaticNodeTypePolicy,
            LoadSpec: StaticNodeTypePolicy,
            Masterdata: StaticNodeTypePolicy,
            MpriseUser: StaticNodeTypePolicy,
            My: StaticNodeTypePolicy,
            OrganizationOwner: StaticNodeTypePolicy,
            PackOutputScanResponse: StaticNodeTypePolicy,
            PackSpec: StaticNodeTypePolicy,
            Permission: StaticNodeTypePolicy,
            Query: StaticNodeTypePolicy,
            QueryLotNumber: StaticNodeTypePolicy,
            Recent: StaticNodeTypePolicy,
            ReduceJobInventoryResult: StaticNodeTypePolicy,
            Sas: StaticNodeTypePolicy,
            ShortcutPage: StaticNodeTypePolicy,
            SignedUpload: StaticNodeTypePolicy,
            TeamPlannedHours: StaticNodeTypePolicy,
            TeamPlannedHoursPerDate: StaticNodeTypePolicy,
            TemplateAttribute: StaticNodeTypePolicy,
            TenantCompressionSettings: StaticNodeTypePolicy,
            TenantHiddenColumns: StaticNodeTypePolicy,
            TenantRoleDataPermission: StaticNodeTypePolicy,
            TenantServiceRole: StaticNodeTypePolicy,
            TenantSettings: StaticNodeTypePolicy,
            TenantUserClaim: StaticNodeTypePolicy,
            TenantUserCollectionSegment: StaticNodeTypePolicy,
            TenantUserDataPermission: StaticNodeTypePolicy,
            ThumbnailSize: StaticNodeTypePolicy,
            TimeRegResourceSummary: StaticNodeTypePolicy,
            TimeRegSummary: StaticNodeTypePolicy,
            TimeRegSummaryResources: StaticNodeTypePolicy,
            TransferCarriersResult: StaticNodeTypePolicy,
            UserSettings: StaticNodeTypePolicy,
            WorkCarrierIdAssemblyInputSpec: StaticNodeTypePolicy,
            WorkItemConsumptionEntryLotNumber: StaticNodeTypePolicy,
            WorkItemCountByName: StaticNodeTypePolicy,
            WorkItemOffset: StaticNodeTypePolicy,
            WorkItemTemplateTask: StaticNodeTypePolicy,
            WorkTrackingIdAssemblyInputSpec: StaticNodeTypePolicy,
        };
    